.App {
  text-align: center;
  height: 100%;
}
.grayBackground {
  background-color: #f4f5f5;
}
.arabic-font {
  font-family: "Quicksand", "Cairo", sans-serif !important;
  font-weight: 400;
}
.english-font {
  font-family: "Quicksand", "Cairo", sans-serif !important;
  font-weight: 100;
}
html,
body {
  height: 100%;
  background-color: #f4f5f5;
  font-family: "Quicksand", "Cairo", sans-serif !important;
}
body {
  overflow-y: scroll !important;
}
h6 {
  color: black;
}
#root {
  height: 100%;
}
.App-logo {
  height: 40vmin;
}
.cut-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 59%;
}
.cut-text-description-b2b {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 59%;
}
.cut-text-two-lines-grid-view {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.cut-text-one-line {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.cut-text-two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.cut-text-one-line-list {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 59%;
}
.cut-text-two-lines-list {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 59%;
}

.cut-text-three-lines-jumbo {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 59%;
}

.cut-text-two-lines-list-b2b {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

ul.ui-widget-content::-webkit-scrollbar {
  width: 10px;
}
@media (min-width: 691px) {
  .actionButtonForLargerScreens {
    position: sticky;
  }
  .MuiDrawer-paper {
    width: 41.6vw !important;
    background-color: #f4f5f5 !important;
  }
  .promo_item {
    width: "100%";

    max-height: "180px";
  }
}
/* Promotions Styling Start */
.main_promo {
  text-align: center;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.image {
  height: 100px;
  width: 100px;
  font-size: 25px;
  background: black;
  margin-top: 12px;
  margin-right: 20px;
  margin-left: 20px;
  display: inline-block;
  line-height: 100px;
  border-radius: 20px;
  margin-bottom: 40px;
}

.image-container {
  vertical-align: middle;
  display: inline-block;
  width: 85%;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

.prev,
.next {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 100%;
  margin: 0 auto;
  cursor: pointer;
  color: black;
  transition: 0.6s ease;
}
.next {
  margin-left: 5px;
}
.prev {
  margin-right: 5px;
}
.prev:hover,
.next:hover {
  color: white;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

/* Promotions Styling Ends */

/* Menu Styling Start */

.image_menu {
  height: 90px;
  width: 90px;
}
.category_menu {
  background-color: #f4f5f5;
  opacity: 0.9;
  padding: 16px;
  text-align: left;

  z-index: 1000;
}
/* Menu Styling Ends */

.categories_header {
  background-color: #f4f5f5;
  opacity: 0.9;
  z-index: 9999;
}

.action-button-english {
  width: 100%;
  padding: 7px;
  background-color: white;
  position: fixed;
  bottom: 0px;
  left: 0px;
}

.action-button-arabic {
  width: 100%;
  padding: 7px;
  background-color: white;
  position: fixed;
  bottom: 0px;
  right: 0px;
}

@media only screen and (min-width: 768px) {
  .action-button-english {
    width: 41.6%;
    padding: 7px;
    background-color: white;
    position: fixed;
    bottom: 0px;
    left: 0px;
  }
  .detail_header {
    position: fixed;
    top: 0;
    left: 0;
    height: 60;
    width: 41.6vw !important;
    z-index: 2;
    background-color: white;
  }
  .changeToFixedBackground {
    position: fixed;
    margin-top: 0px !important;
    right: 0px !important;
    width: 100% !important;
  }
  .action-button-arabic {
    width: 41.7%;
    padding: 7px;
    background-color: white;
    position: fixed;
    bottom: 0px;
    right: 0px;
  }

  .historyButtonLargeScreenArabic {
    right: 50vw !important;
    display: inline !important;
  }

  .historyButtonLargeScreenEnglish {
    left: 50vw !important;
    display: inline !important;
  }
  .languageButtonLargeScreenArabic {
    right: 60vw !important;
    display: inline !important;
  }
  .languageButtonLargeScreenEnglish {
    left: 60vw !important;
    display: inline !important;
  }

  .currencyButtonLargeScreenArabic {
    right: 65vw !important;
    display: inline !important;
  }
  .currencyButtonLargeScreenEnglish {
    left: 65vw !important;
    display: inline !important;
  }

  .menuButtonLargeScreenEnglish {
    left: 45vw !important;
    display: inline !important;
  }
  .menuButtonLargeScreenArabic {
    right: 45vw !important;
    display: inline !important;
  }
  .detail_header_ar {
    position: fixed;
    top: 0;
    right: 0;
    height: 60;
    z-index: 2;
    width: 41.63vw !important;
  }
  .languageMakeDisappearForLargeScreen {
    display: none !important;
  }
  .totalCharge-english {
    width: 41.6% !important;
  }
  .headerTitleLargeScreen {
    position: relative !important;
    left: -37px !important;
  }
  .stepperWidth {
    width: 41.6vw !important;
  }
  .left-auto {
    left: auto !important;
  }
  .bannerLargeScreen {
    width: 41.61vw !important;
  }
}
.header-button-cricle {
  min-height: 40px !important;
  border-radius: 20px !important;
  background-color: white !important;
}
.cut-text-one-line-product-name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 68%;
}
.product-nutrition {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 93%;
}
.control-dots {
  padding: 0px !important;
  padding-right: 2px !important;
}
.control-arrow {
  background: transparent !important;
}

.modal {
  content: " ";
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.5);
}

.header {
  background-color: #f4f5f5;

  padding: 16px;
  text-align: left;
  margin-bottom: 0;
  z-index: 1000;
}
.container {
  width: 100vw !important;
  background-color: #f4f5f5;
}
.gap {
  height: 500px;
}
.gap.short {
  height: 250px;
}
.gap.tall {
  height: 1000px;
}
.container.relative {
  overflow-y: auto;
}

.route-wrapper {
  position: relative;
}

.route-wrapper > div {
  position: absolute;
}

.back_arrow:hover {
  background: none !important;
}
.back_arrow:focus {
  outline: none !important;
}
.quantity_button {
  font-size: 30px !important ;
}

.quantity_button:hover {
  cursor: pointer;
}

.detail_header {
  position: fixed;
  top: 0;
  left: 0;
  height: 60;
  z-index: 2;
  background-color: white;
}

.Mycontainer {
  border-radius: 5px;
}

.MuiCheckbox-root {
  color: grey !important;
}
.MuiCheckbox-root:hover {
  background: none !important;
}

.detail_header_ar {
  position: fixed;
  top: 0;
  right: 0;
  height: 60;
  z-index: 2;
  background-color: white;
}

.MuiSelect-icon {
  left: 0px !important;
  display: none !important;
}

::-webkit-scrollbar {
  width: 0px !important; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: transparent;

  border-radius: 15px;
}
.mapsMarker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;

  user-select: none;
  transform: translate(-50%, -50%);
}
.mapsMarker:hover {
  z-index: 1;
}
.container {
  padding: 0px !important;
}
.makeLinkNormal {
  color: black;
}
.makeLinkNormal:hover {
  color: black;
  text-decoration: none;
}

.accordionBorderEnglish {
  border-left-width: 4px;
  border-left-style: solid;
}

.accordionBorderArabic {
  border-right-width: 4px;
  border-right-style: solid;
}

.menu-item {
  border: none !important;
  outline: none !important;
  border: 1px solid #666 !important;
  min-width: 120px;
  background: white;
  min-height: 70px;

  color: #666;
  border-radius: 3px;

  font-size: 14px;
}

.scroll-menu-arrow {
  padding: 0px;
  cursor: pointer;
}
.active {
  font-weight: bolder;
}
.ar_active {
  color: green;
  border: 1px solid green !important;
  border-bottom: 2px inset green !important;
  box-shadow: -2px 1px 0px 0px green !important;
}

.scroll-container {
  display: flex;
  width: 80%;
  user-select: none;
  overflow-x: auto;
}

.scroll-container div {
  margin-right: 10px;
}

.pointer {
  cursor: pointer;
}

.rtl {
  direction: rtl;
}

label {
  margin: 0px;
  font-size: 14px;
}

.stepperWidth {
  width: 100%;
}
/* .areaSelectedStyle {
  background-color: #0099cc !important;
  color: white !important;
  border-radius: 5px !important;
} */

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.dot-small {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
}

.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #00cae9;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -25px 0 0 -5px;
}
.pin:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 7px 0 0 -6px;
  background: #e6e6e6;
  position: absolute;
  border-radius: 50%;
}

.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}
.pulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 19px 0px 0px -8px;
  transform: rotateX(55deg);
  z-index: -2;
}
.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -14px 0 0 -19px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #00cae9;
  animation-delay: 1.1s;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}

.MuiFormGroup-root {
  margin-bottom: 30px !important;
}

.MuiDrawer-paper {
  background-color: #f4f5f5 !important;
}
.MuiCard-root {
  width: 100%;
  min-height: 120px;

  margin: auto;
}

.MuiInputLabel-root {
  color: #6c757d !important;
}

.MuiInputLabel-shrink {
  font-size: 20px !important;
}

/* .MuiInput-input {
  color: #00ccbc !important;
} */

.half-line {
  margin: 0;
  height: 1px;
  width: 95%;
  padding: auto;
  margin-left: auto;
  margin-right: auto;
  border-top: 1px solid #dee2e6 !important;
}
.free-space-30 {
  height: 30px;
  background-color: #f4f5f5;
}
.free-space-20 {
  height: 20px;
  background-color: white;
}
.free-space-50 {
  height: 50px;
  background-color: #f4f5f5;
}
.free-space-50-white {
  height: 50px;
  background-color: white;
}
.free-space-60 {
  height: 60px;
  background-color: #f4f5f5;
}
.free-space-60-white {
  height: 60px;
  background-color: #f4f5f5;
}
.free-space-70 {
  height: 70px;
  background-color: #f4f5f5;
}
.free-space-70-white {
  height: 70px;
  background-color: white;
}
.free-space-40-white {
  height: 40px;
  background-color: white;
}
.free-space-110 {
  height: 110px;
  background-color: #f4f5f5;
}
.free-space-190-white {
  height: 190px;
  background-color: white;
}
.free-space-160-white {
  height: 160px;
  background-color: white;
}
.free-space-80 {
  height: 80px;
  background-color: #f4f5f5;
}
.free-space-80-white {
  height: 80px;
  background-color: white;
}
.free-space-90 {
  height: 90px;
  background-color: #f4f5f5;
}
.free-space-105 {
  height: 105px;
  background-color: #f4f5f5;
}
.free-space-90-white {
  height: 90px;
  background-color: white;
}
.free-space-115 {
  height: 115px;
  background-color: #f4f5f5;
}
.free-space-145 {
  height: 145px;
  background-color: #f4f5f5;
}
.free-space-170 {
  height: 170px;
  background-color: #f4f5f5;
}
.free-space-135 {
  height: 135px;
  background-color: #f4f5f5;
}

.free-space-165 {
  height: 165px;
  background-color: #f4f5f5;
}
.free-space-190 {
  height: 190px;
  background-color: #f4f5f5;
}

.free-space-210 {
  height: 210px;
  background-color: #f4f5f5;
}
.free-space-240 {
  height: 240px;
}
.free-space-58 {
  height: 58px;
  background-color: #f4f5f5;
}
.free-space-400-white {
  height: 400px;
  background-color: white;
}
/* 
.icon-addon.addon-md .glyphicon,
.icon-addon .glyphicon,
.icon-addon.addon-md .fa,
.icon-addon .fa {
  position: absolute;
  z-index: 2;
  left: 10px;
  font-size: 14px;
  width: 20px;
  margin-left: -2.5px;
  text-align: center;
  padding: 10px 0;
  top: 1px;
} */
.MuiButton-root {
  min-width: 40px !important;
}

.larger-text-tracking {
  font-size: 1.3rem !important;
  text-align: left !important;
}
.larger-text-tracking-arabic {
  font-size: 1.3rem !important;
  text-align: right !important;
  margin-right: 8px !important;
}

.vertical-line-arabic {
  border-right-style: solid;
  border-right-width: 1px;
  border-left-width: 0px !important;
}
.vertical-line-arabic-root {
  margin-left: 0px !important;
  margin-right: 12px;
}
.step-label-icon-container-arabic {
  padding-right: 3px !important;
}
.step-content-arabic {
  border-right: 1px solid #bdbdbd !important;
  border-left: none !important;
  margin-right: 12px !important;
}

.snackbar-bottom-70 {
  bottom: 70px !important;
}

.large-star {
  font-size: 2.2rem;
  margin-right: 4px;
  margin-left: 4px;
}

.flip-180 {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.giveMeEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* number of lines to show */
}

.nowrap {
  white-space: nowrap !important;
  text-overflow: unset !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.SearchButtonLargeScreenArabic {
  right: 55vw !important;
  display: inline !important;
}

.SearchButtonLargeScreenEnglish {
  left: 55vw !important;
  display: inline !important;
}

button:hover {
  outline: none !important;
}
button:focus {
  outline: none !important;
}
button:active {
  outline: none !important;
}
.MuiList-root:hover {
  outline: none !important;
}
.MuiList-root:focus {
  outline: none !important;
}
.MuiList-root:active {
  outline: none !important;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.slide-right {
  -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(-80px);
    transform: translateX(-80px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-80px);
    transform: translateX(-80px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

.scrollable-div > .row {
  overflow-x: auto;
  white-space: nowrap;
  flex-wrap: nowrap;
  scroll-behavior: smooth;
}
.scrollable-div > .row > .col-4 {
  display: inline-block;
  float: none;
}

.disable-scroll-bar::-webkit-scrollbar {
  display: none !important;
  width: 0px !important;
  background: transparent !important; /* Chrome/Safari/Webkit */
}

.disable-scroll-bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.preventDrag {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.cut-text-two-lines-banner {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.cut-text-one-line-tracking {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.react-calendar {
  margin: auto !important;
  border: none !important;
  margin-bottom: 40px !important;
  font-family: "Quicksand", "Cairo", sans-serif !important;
  width: unset !important;
  max-width: 96% !important;
}

.react-calendar__month-view__days__day--weekend {
  color: black !important;
}
.react-calendar__tile--now {
  background: white !important;
}

.react-calendar__tile {
  padding: 1.35em 0.5em !important;
  font-family: "Quicksand", "Cairo", sans-serif !important;
}

.react-calendar__navigation__label__labelText {
  font-family: "Quicksand", "Cairo", sans-serif !important;
}

.react-calendar__tile:disabled {
  background: #d3d3d3 !important;
}

abbr {
  text-decoration: none !important;
  color: black !important;
}

.react-calendar__tile--active {
  background: #00bd46 !important;
  color: white !important;
}

.react-calendar__tile--active abbr {
  color: white !important;
}

.option-scroll-parent > * {
  margin-left: 15px;
  margin-right: 15px;
}

.option-quantity-button {
  position: relative;
}
.option-quantity-button::after {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  z-index: 2;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.error-placeholder::placeholder {
  font-weight: 300;
  color: red;
}

/* WhatsAppWidget main button */
._1bpcM {
  align-items: center;
  align-self: flex-start;
  background-color: #29ac00 !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 3px 1px !important;
  color: white !important;
  cursor: pointer;
  display: flex;
  font-size: 1.5em !important;
  height: 60px !important;
  justify-content: center;
  margin: 0px !important;
  outline: transparent;
  -webkit-user-select: none;
  user-select: none;
  width: 40px !important;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  position: fixed !important;
  bottom: 410px !important;
  z-index: 1500 !important;
}

/* WhatsAppWidget main button */
._bvAzs {
  display: none;
  z-index: 1500 !important;
  width: 50px !important;
  height: 50px !important;
}

/* WhatsAppWidget popup window */
._2iWL7 {
  width: 90% !important;
  max-width: 380px !important;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px 1px;
  flex-direction: column;
  border-radius: 10px !important;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  transform: translate3d(0px, 0px, 0px);
  touch-action: auto;
  margin-bottom: 15px;
  position: fixed !important;
  margin: 20px;
  bottom: 227px !important;
  font-family: "Quicksand", "Cairo", sans-serif !important;
  z-index: 1500 !important;
}

/* WhatsAppWidget send button */
._2W95M {
  background: #29ac00 !important;
  font-family: "Quicksand", "Cairo", sans-serif !important;
}

/* WhatsAppWidget input field */
._2-mAj {
  font-family: "Quicksand", "Cairo", sans-serif !important;
}

._2-mAj:focus {
  outline: none !important;
}

.card-body {
  padding: 15px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.wrap-collabsible {
  margin-bottom: 1.2rem 0;
}

.leaflet-marker-icon {
  margin-left: -14px !important;
  margin-top: -32px !important;
  width: 30px !important;
  height: 30px !important;
}

.leaflet-top,
.leaflet-bottom {
  z-index: 400 !important;
}

.discount-sign {
  padding: 1px 5px;
  border-radius: 3px;
  text-align: center;
  font-size: 16px;
  color: white;
  font-weight: bold;
  top: 8px;
  position: absolute;
}

@media (max-width: 768px) {
  .product-header-padding {
    padding-top: 55px !important;
  }
  .sticky-category {
    top: 54px !important;
  }
}
@media (max-width: 768px) {
  .product-header-margin {
    margin-top: 55px !important;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

@media (min-width: 320px) and (max-width: 523px) {
  .product-header-sm-screen {
    line-height: 1.5 !important;
    height: 47px !important;
  }
}
@media (min-width: 768px) and (max-width: 1256px) {
  .product-header-sm-screen {
    line-height: 1.5 !important;
    height: 47px !important;
  }
}
@media (min-width: 976px) and (max-width: 1256px) {
  .height-32 {
    height: 34px !important;
    line-height: 2.425 !important;
  }
}
@media (min-width: 407px) and (max-width: 523px) {
  .height-32 {
    height: 34px !important;
    line-height: 2.425 !important;
  }
}

.center-items > .MuiSkeleton-root {
  margin: auto !important;
}

.instagram-widget-background {
  background: -webkit-radial-gradient(
    30% 107%,
    circle,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: -o-radial-gradient(
    30% 107%,
    circle,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: -webkit-radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
}

.tiktok-widget-background {
  background: rgb(37, 244, 238); /* Fallback for older browsers */
  background: linear-gradient(
    17deg,
    rgba(37, 244, 238, 1) 18%,  /* Cyan */
    rgba(254, 44, 85, 1) 50%,    /* Red */
    rgba(0, 0, 0, 1) 85%         /* Black */
  );
}






.dense-option-layout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 10px;
}

.smart-search-highlight {
  background-color: #f6e699;
}

.character {
  border: none;
  font-size: 20px;
  border-radius: 8px;
  width: 10px;

  /* light theme */
  color: #272729;
  background-color: #f6f5fa;
  box-shadow: 0 2px 0 #e4e2f5;
}
.character-error {
  border: 1px solid red;
  font-size: 20px;
  border-radius: 8px;
  width: 10px;

  /* light theme */
  color: #272729;
  background-color: #fc505021;
  box-shadow: 0 2px 0 #ff496a;
}
.character--selected--error {
  outline: 2px solid red;
}
.vi__container {
  width: 100%;
}
.vi__wrapper {
  width: 100%;
}

.e-bulter-bottom {
  bottom: 260px !important;
}

strong {
  font-weight: bold !important;
}

.order-card {
  transition: all 0.3s ease; /* Add a smooth transition to the entire order card */
}

.rating-form {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease, height 0.3s ease; /* Smooth transition for opacity and height */
}

.visible {
  opacity: 1;
  height: auto;
}

.hidden {
  opacity: 0;
  height: 0;
}

@media only screen and (max-width: 767px) {
  .action-button-tabs-space {
    bottom: 46px !important;
  }
}


.styles__subtitle--abcd5 {
  color: black !important;
  display: none !important;

}
