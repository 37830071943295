.layoutProductTitleEnglishB2B {
  position: absolute;
  top: 8px;
  font-size: 14px;
}

.layoutProductTitleArabicB2B {
  position: absolute;
  top: 8px;
  right: 120px;
  font-size: 14px;
}

.layoutProductDescriptionEnglishB2B {
  position: absolute;
  top: 30px;
  font-size: 14px;
}

.layoutProductDescriptionArabicB2B {
  position: absolute;
  top: 30px;
  right: 120px;
  font-size: 14px;
  direction: rtl;
}

.prepTimeEnglishB2B {
  font-size: 14px;
  position: absolute;
  top: 73px;
}

.prepTimeArabicB2B {
  font-size: 14px;
  position: absolute;
  top: 73px;
  right: 120px;
}
.priceEnglishB2B {
  font-size: 14px;
  position: absolute;
  top: 60px;
  right: 0px;
}
.quantityEnglishB2B {
  font-size: 14px;
  position: relative;
  top: 25px;
  right: 0px;
}
.quantityArabicB2B {
  font-size: 14px;
  position: relative;
  top: 25px;
  left: 0px;
}
.priceArabicB2B {
  font-size: 14px;
  position: absolute;
  top: 74px;
  left: 20px;
}
