.pageEnTitle {
    display: block;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    direction: ltr;
  }
  
  .pageArTitle {
    display: block;
    text-align: right;
    font-size: 16px;
    font-weight: bold;
    direction: rtl;
  }
  
  .pageEnTitleIcon {
    height: 18px;
    vertical-align: middle;
    fill: #0099cc;
    position: relative;
    bottom: 3px;
    margin-right: 10px;
    width: 18px;
  }
  
  .pageArTitleIcon {
    height: 18px;
    vertical-align: middle;
    fill: #0099cc;
    position: relative;
    bottom: 3px;
    margin-left: 10px;
    width: 18px;
  }