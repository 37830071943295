.bordered {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}

.box-title {
  margin-bottom: 5px;
  font-weight: bold;
  color: #5b5b5b;
  padding: 0px 10px;
  font-size: 1rem;
}
